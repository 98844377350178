<template>
    <div class="main-wrapper">
        <!--        <div class="main-tab">考试数据</div>-->
        <el-table :data="examList" class="customTable" style="width: 100%; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="exam_name" label="考试名称" align="left" width="200"></el-table-column>
            <el-table-column prop="student_count" label="考试人数" align="center"></el-table-column>
            <el-table-column></el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <div class="a-link" @click="viewDetail(scope.row)">
                            查看详情
                        </div>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {examination_data_index} from '@/utils/apis'

    export default {
        data() {
            return {
                examList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                    type: 1
                }
                examination_data_index(params).then((res) => {
                    this.examList = res.data.list ? res.data.list : [];
                    this.listPages.total = res.data.total;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            viewDetail(row) {
                let role = Number(localStorage.getItem('role'));
                let path = role === 4 ? '/judges/examstudata' : '/examManage/examDataStudent'
                console.log('row', row);
                this.$router.push({
                    path: path,
                    query: {
                        examId: row.exam_type ? row.origin_exam_id : row.id,
                        type: row.exam_level
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }

    .main-tab {
        font-size: 16px;
        line-height: 1;
        padding-bottom: 14px;
        border-bottom: 1px solid #F1F5FF;
    }

    .a-link {
        color: inherit;
    }
</style>